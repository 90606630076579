<template>
  <Lottie
    id="map-netherlands"
    class="animation"
    :options="mapAnimationOptions"
  />
</template>

<script>
import Lottie from 'chimera/all/components/Lottie'
import { getWithText } from 'chimera/all/themes/mars/lottie/maps/netherlands'

export default {
  name: 'MapNetherlands',

  components: {
    Lottie,
  },

  props: {
    text: {
      type: String,
      default: 'Test test',
    },
  },

  computed: {
    /**
     *
     * @returns {{animationData: *}}
     */
    mapAnimationOptions() {
      return {
        animationData: getWithText(this.text),
      }
    },
  },
}
</script>

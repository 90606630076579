/**
 * @param {string} text
 * @param {string} font
 * @returns {object}
 */
export function getWithText(text, font = 'Assistant') {
  return {
    v: '4.8.0',
    meta: { g: 'LottieFiles AE 1.0.0', a: '', k: '', d: '', tc: '' },
    fr: 29.9700012207031,
    ip: 0,
    op: 870.000035435826,
    w: 900,
    h: 1040,
    nm: 'map_dot_pulse',
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'Dot 12',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [770, 348, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 753,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 769,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 855,
                s: [35, 35, 100],
              },
              { t: 883.000035965327, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: 'Pulse 12',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [770, 348, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 753,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 769,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 844,
                s: [100, 100, 100],
              },
              { t: 883.000035965327, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'Dot 11',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [362, 609, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 690,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 706,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 792,
                s: [35, 35, 100],
              },
              { t: 820.000033399285, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'Pulse 11',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [362, 609, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 690,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 706,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 781,
                s: [100, 100, 100],
              },
              { t: 820.000033399285, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: 'Dot 10',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [410, 279, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 627,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 643,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 729,
                s: [35, 35, 100],
              },
              { t: 757.000030833242, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 4,
        nm: 'Pulse 10',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [410, 279, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 627,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 643,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 718,
                s: [100, 100, 100],
              },
              { t: 757.000030833242, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 4,
        nm: 'Dot 9',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [540, 151, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 562,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 578,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 664,
                s: [35, 35, 100],
              },
              { t: 692.000028185738, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 8,
        ty: 4,
        nm: 'Pulse 9',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [540, 151, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 562,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 578,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 653,
                s: [100, 100, 100],
              },
              { t: 692.000028185738, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 9,
        ty: 4,
        nm: 'Dot 8',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [768, 541, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 495,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 511,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 597,
                s: [35, 35, 100],
              },
              { t: 625.000025456772, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 10,
        ty: 4,
        nm: 'Pulse 8',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [768, 541, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 495,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 511,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 586,
                s: [100, 100, 100],
              },
              { t: 625.000025456772, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 11,
        ty: 4,
        nm: 'Dot 7',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [570, 979, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 428,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 444,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 530,
                s: [35, 35, 100],
              },
              { t: 558.000022727806, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 12,
        ty: 4,
        nm: 'Pulse 7',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [570, 979, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 428,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 444,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 519,
                s: [100, 100, 100],
              },
              { t: 558.000022727806, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 13,
        ty: 4,
        nm: 'Dot 6',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [502, 456, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 365,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 381,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 467,
                s: [35, 35, 100],
              },
              { t: 495.000020161763, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 14,
        ty: 4,
        nm: 'Pulse 6',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [502, 456, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 365,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 381,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 456,
                s: [100, 100, 100],
              },
              { t: 495.000020161763, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 15,
        ty: 4,
        nm: 'Dot 5',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [791, 304, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 292,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 308,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 394,
                s: [35, 35, 100],
              },
              { t: 422.000017188412, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 16,
        ty: 4,
        nm: 'Pulse 5',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [791, 304, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 292,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 308,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 383,
                s: [100, 100, 100],
              },
              { t: 422.000017188412, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 2.00000008146167,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 17,
        ty: 4,
        nm: 'Dot 4',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [364, 715, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 221,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 237,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 323,
                s: [35, 35, 100],
              },
              { t: 351.000014296523, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 1.00000004073083,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 18,
        ty: 4,
        nm: 'Pulse 4',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [364, 715, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 221,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 237,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 312,
                s: [100, 100, 100],
              },
              { t: 351.000014296523, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 1.00000004073083,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 19,
        ty: 4,
        nm: 'Dot 3',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [492, 609, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 141,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 157,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 243,
                s: [35, 35, 100],
              },
              { t: 271.000011038056, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 1.00000004073083,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 20,
        ty: 4,
        nm: 'Pulse 3',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [492, 609, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 141,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 157,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 232,
                s: [100, 100, 100],
              },
              { t: 271.000011038056, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 1.00000004073083,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 21,
        ty: 4,
        nm: 'Dot 2',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [500, 407, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 72,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 88,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 174,
                s: [35, 35, 100],
              },
              { t: 202.000008227629, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 1.00000004073083,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 22,
        ty: 4,
        nm: 'Pulse 2',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [500, 407, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 72,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 88,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 163,
                s: [100, 100, 100],
              },
              { t: 202.000008227629, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 1.00000004073083,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 23,
        ty: 4,
        nm: 'Dot 1',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [296, 519, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 0,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 16,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 102,
                s: [35, 35, 100],
              },
              { t: 130.000005295009, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.997536094516, 0.659450157016, 0.536094515931, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 24,
        ty: 4,
        nm: 'Pulse 1',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [296, 519, 0], ix: 2 },
          a: { a: 0, k: [-147.469, 3.094, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 0,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 16,
                s: [35, 35, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 91,
                s: [100, 100, 100],
              },
              { t: 130.000005295009, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [67.643, 67.643], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.419607843137, 0.207843137255, 1], ix: 4 },
                o: { a: 0, k: 30, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-149.179, 1.821], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 26,
        ty: 4,
        nm: 'Capa 1/Netherlands_map Outlines',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [450, 520, 0], ix: 2 },
          a: { a: 0, k: [1128, 1297.5, 0], ix: 1 },
          s: { a: 0, k: [37.7, 37.7, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.574],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 2575.541], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.574],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 2575.541], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 2',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 2575.541], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 3',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 3,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 2516.796], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 4',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 4,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 2516.796], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 5',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 5,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 2516.796], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 6',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 6,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.147],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 2459.352], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 7',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 7,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.147],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 2459.352], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 8',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 8,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.147],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 2459.352], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 9',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 9,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.147],
                      [-19.149, -0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 2400.608], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 10',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 10,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.147],
                      [-19.147, -0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 2400.608], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 11',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 11,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.147],
                      [-19.149, -0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 2400.608], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 12',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 12,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.147],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 2343.163], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 13',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 13,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.147],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 2343.163], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 14',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 14,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 2284.419], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 15',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 15,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 2284.419], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 16',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 16,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 2226.975], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 17',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 17,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 2226.975], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 18',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 18,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 2226.975], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 19',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 19,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.147],
                      [-19.149, 0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 2168.23], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 20',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 20,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.147],
                      [-19.149, 0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 2168.23], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 21',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 21,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.147],
                      [-19.147, 0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 2168.23], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 22',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 22,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.147],
                      [-19.149, 0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 2168.23], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 23',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 23,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.147],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 2110.787], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 24',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 24,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.147],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 2110.787], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 25',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 25,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.147],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 2110.787], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 26',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 26,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 27',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 27,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 28',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 28,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 29',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 29,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 30',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 30,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 31',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 31,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 32',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 32,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 33',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 33,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 34',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 34,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 35',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 35,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 36',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 36,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.147, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 37',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 37,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 38',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 38,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0.001, 19.147],
                      [-19.147, 0.001],
                      [0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 1878.41], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 39',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 39,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.147],
                      [-19.149, 0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 1878.41], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 40',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 40,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.147],
                      [-19.149, 0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 1878.41], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 41',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 41,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.147],
                      [-19.149, 0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 1878.41], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 42',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 42,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 43',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 43,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 44',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 44,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 45',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 45,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 46',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 46,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 1762.221], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 47',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 47,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 1762.221], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 48',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 48,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 1762.221], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 49',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 49,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.148],
                      [-19.149, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 50',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 50,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.147, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 51',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 51,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.148],
                      [-19.149, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 52',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 52,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.574],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 53',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 53,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 54',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 54,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 55',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 55,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 56',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 56,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 57',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 57,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 58',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 58,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 59',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 59,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 60',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 60,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 61',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 61,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 62',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 62,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 63',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 63,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 64',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 64,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 65',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 65,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 66',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 66,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 67',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 67,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 68',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 68,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 69',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 69,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 70',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 70,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 71',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 71,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 72',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 72,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 73',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 73,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 74',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 74,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 75',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 75,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 76',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 76,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 77',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 77,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 78',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 78,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 79',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 79,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 80',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 80,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 81',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 81,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 82',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 82,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 83',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 83,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 84',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 84,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 85',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 85,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 86',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 86,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 87',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 87,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.577],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 88',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 88,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.577],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 89',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 89,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.577],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [-0.002, 19.148],
                      [-19.149, -0.001],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 90',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 90,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.577],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 91',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 91,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.577],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.147, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 92',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 92,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.577],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 93',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 93,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.577],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 94',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 94,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.577],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 95',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 95,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2062.126, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 96',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 96,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 97',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 97,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 98',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 98,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 99',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 99,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 100',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 100,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 101',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 101,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 102',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 102,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 103',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 103,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 104',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 104,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2111.737, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 105',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 105,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 106',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 106,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 107',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 107,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 108',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 108,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 109',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 109,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 110',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 110,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 111',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 111,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 112',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 112,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 113',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 113,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2149.163, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 114',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 114,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2062.126, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 115',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 115,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 116',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 116,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 117',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 117,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 118',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 118,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 119',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 119,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 120',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 120,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 121',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 121,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 122',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 122,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 123',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 123,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2111.737, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 124',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 124,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 125',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 125,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 126',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 126,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 127',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 127,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 128',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 128,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 129',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 129,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 130',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 130,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 131',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 131,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 132',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 132,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2149.163, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 133',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 133,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2062.126, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 134',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 134,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 135',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 135,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 136',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 136,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 137',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 137,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 138',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 138,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 139',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 139,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 140',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 140,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 141',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 141,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 142',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 142,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2111.737, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 143',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 143,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 144',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 144,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 145',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 145,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 146',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 146,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 147',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 147,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 148',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 148,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 149',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 149,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 150',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 150,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [-0.002, 19.148],
                      [-19.149, -0.001],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 151',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 151,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 152',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 152,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 153',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 153,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 154',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 154,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 155',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 155,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 156',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 156,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.147, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 157',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 157,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 158',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 158,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 159',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 159,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 160',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 160,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 161',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 161,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 162',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 162,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 163',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 163,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 164',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 164,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 165',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 165,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2149.163, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 166',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 166,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2062.126, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 167',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 167,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 168',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 168,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 169',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 169,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 170',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 170,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 171',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 171,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 172',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 172,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 173',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 173,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 174',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 174,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 175',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 175,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2111.737, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 176',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 176,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 177',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 177,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 178',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 178,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 179',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 179,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 180',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 180,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 181',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 181,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 182',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 182,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 183',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 183,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 184',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 184,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2149.163, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 185',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 185,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2062.126, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 186',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 186,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 187',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 187,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 188',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 188,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 189',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 189,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 190',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 190,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 191',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 191,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 192',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 192,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 193',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 193,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 194',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 194,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2111.737, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 195',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 195,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 196',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 196,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 197',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 197,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 198',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 198,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 199',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 199,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 200',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 200,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 201',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 201,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 202',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 202,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 203',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 203,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2149.163, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 204',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 204,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2062.126, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 205',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 205,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 206',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 206,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 207',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 207,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 208',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 208,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 209',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 209,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 210',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 210,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 211',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 211,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 212',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 212,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 213',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 213,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2198.774, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 214',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 214,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2111.737, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 215',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 215,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 216',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 216,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 217',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 217,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 218',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 218,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 219',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 219,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 220',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 220,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 221',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 221,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 222',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 222,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 223',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 223,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2236.2, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 224',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 224,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2149.163, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 225',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 225,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2062.126, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 226',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 226,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 227',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 227,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 228',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 228,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 229',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 229,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 230',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 230,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 231',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 231,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 232',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 232,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 233',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 233,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 234',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 234,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2198.774, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 235',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 235,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.147, -0.001],
                      [-0.001, 19.148],
                      [-19.148, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2111.737, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 236',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 236,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 237',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 237,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 238',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 238,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [-0.002, 19.148],
                      [-19.149, -0.001],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 239',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 239,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 240',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 240,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.147, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 241',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 241,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 242',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 242,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 243',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 243,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 244',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 244,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2149.163, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 245',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 245,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2062.126, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 246',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 246,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 247',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 247,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 248',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 248,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 249',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 249,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 250',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 250,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 251',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 251,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 252',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 252,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 253',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 253,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 254',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 254,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2198.774, 251.773], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 255',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 255,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2111.737, 251.773], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 256',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 256,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 251.773], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 257',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 257,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 251.773], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 258',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 258,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 251.773], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 259',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 259,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 251.774], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 260',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 260,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.147, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 251.774], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 261',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 261,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 251.774], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 262',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 262,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 251.774], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 263',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 263,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 251.774], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 264',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 264,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2149.163, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 265',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 265,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.147, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2062.126, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 266',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 266,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 267',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 267,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 268',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 268,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 269',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 269,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1713.979, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 270',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 270,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1626.941, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 271',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 271,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1539.904, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 272',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 272,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1452.868, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 273',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 273,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 274',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 274,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [2024.7, 135.586], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 275',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 275,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1937.663, 135.586], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 276',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 276,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [-0.002, 19.148],
                      [-19.149, -0.001],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1850.627, 135.586], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 277',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 277,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1763.59, 135.585], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 278',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 278,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 135.585], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 279',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 279,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 135.585], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 280',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 280,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 135.585], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 281',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 281,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 135.585], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 282',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 282,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.002, 19.148],
                      [-19.149, 0],
                      [-0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1975.09, 76.841], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 283',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 283,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1888.053, 76.841], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 284',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 284,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1801.016, 76.841], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 285',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 285,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1365.83, 76.841], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 286',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 286,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.147, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1676.552, 19.398], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 287',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 287,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1589.516, 19.398], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 288',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 288,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1502.479, 19.398], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 289',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 289,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1415.441, 19.398], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 290',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 290,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.147],
                      [-19.147, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 2459.352], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 291',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 291,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.147],
                      [-19.147, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 2110.787], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 292',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 292,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.147],
                      [-19.148, 0],
                      [0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 2110.787], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 293',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 293,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.147],
                      [-19.148, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [367.546, 2110.787], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 294',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 294,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.147],
                      [-19.148, 0],
                      [-0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [280.509, 2110.787], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 295',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 295,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 296',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 296,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 297',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 297,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 298',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 298,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [492.009, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 299',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 299,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [404.972, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 300',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 300,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [317.935, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 301',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 301,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [230.897, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 302',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 302,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [143.861, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 303',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 303,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [56.825, 2052.042], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 304',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 304,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 305',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 305,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.001, 19.148],
                      [-19.149, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 306',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 306,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 307',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 307,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 308',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 308,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [628.657, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 309',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 309,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [541.62, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 310',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 310,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [367.546, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 311',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 311,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [193.472, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 312',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 312,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [106.435, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 313',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 313,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [19.398, 1994.598], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 314',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 314,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 315',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 315,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.002, 19.148],
                      [-19.148, -0.001],
                      [0.002, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 316',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 316,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 317',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 317,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [-0.001, 19.148],
                      [-19.148, -0.001],
                      [-0.001, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 318',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 318,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 319',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 319,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 320',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 320,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 321',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 321,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [579.045, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 322',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 322,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [492.009, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 323',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 323,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [-0.001, 19.148],
                      [-19.148, -0.001],
                      [-0.001, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [404.972, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 324',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 324,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [317.935, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 325',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 325,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.149],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [230.897, 1935.855], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 326',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 326,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.147],
                      [-19.147, 0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 1878.41], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 327',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 327,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [-0.001, 19.147],
                      [-19.149, 0.001],
                      [-0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 1878.41], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 328',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 328,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0.001, 19.147],
                      [-19.148, 0.001],
                      [0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 1878.41], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 329',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 329,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0.001, 19.147],
                      [-19.148, 0.001],
                      [0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 1878.41], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 330',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 330,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 331',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 331,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 332',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 332,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 333',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 333,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [715.693, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 334',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 334,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [628.657, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 335',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 335,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [541.62, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 336',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 336,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [367.546, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 337',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 337,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [280.509, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 338',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 338,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [193.472, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 339',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 339,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [106.435, 1878.411], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 340',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 340,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 341',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 341,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 342',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 342,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 343',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 343,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 344',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 344,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 345',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 345,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 346',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 346,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 347',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 347,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [666.083, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 348',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 348,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [579.045, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 349',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 349,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [492.009, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 350',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 350,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [404.972, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 351',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 351,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [317.935, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 352',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 352,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [230.897, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 353',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 353,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [143.861, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 354',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 354,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [56.825, 1819.666], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 355',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 355,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 1762.221], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 356',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 356,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.001, 19.148],
                      [-19.149, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 1762.221], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 357',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 357,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 1762.221], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 358',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 358,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 1762.221], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 359',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 359,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.147],
                      [-19.148, -0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 1762.222], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 360',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 360,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [-0.001, 19.147],
                      [-19.148, -0.001],
                      [-0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 1762.222], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 361',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 361,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.147],
                      [-19.148, -0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 1762.222], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 362',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 362,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.147],
                      [-19.148, -0.001],
                      [0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [715.693, 1762.222], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 363',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 363,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0.001, 19.147],
                      [-19.148, -0.001],
                      [0.001, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [628.657, 1762.222], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 364',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 364,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.147],
                      [-19.148, -0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [541.62, 1762.222], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 365',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 365,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.147],
                      [-19.148, -0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [454.583, 1762.222], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 366',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 366,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.147],
                      [-19.148, -0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [367.546, 1762.222], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 367',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 367,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.147],
                      [-19.148, -0.001],
                      [0, -19.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [193.472, 1762.222], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 368',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 368,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.574],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.148],
                      [-19.149, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 369',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 369,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.574],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0.002, 19.148],
                      [-19.148, 0.001],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 370',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 370,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 371',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 371,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [-0.001, 19.148],
                      [-19.148, 0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 372',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 372,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 373',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 373,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.574],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0.001, 19.148],
                      [-19.148, 0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 374',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 374,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0, 19.148],
                      [-19.149, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 375',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 375,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [-0.001, 19.148],
                      [-19.148, 0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [666.083, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 376',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 376,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [579.045, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 377',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 377,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [492.009, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 378',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 378,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.574, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [-0.001, 19.148],
                      [-19.148, 0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [404.972, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 379',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 379,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [317.935, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 380',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 380,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [230.897, 1703.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 381',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 381,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.147, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 382',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 382,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [-0.001, 19.148],
                      [-19.149, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 383',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 383,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.574],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 384',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 384,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.574],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 385',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 385,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.574],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 386',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 386,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [-0.001, 19.148],
                      [-19.148, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 387',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 387,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.574],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [715.693, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 388',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 388,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.574],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [628.657, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 389',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 389,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [541.62, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 390',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 390,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.574],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [454.583, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 391',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 391,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.574],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [367.546, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 392',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 392,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [-0.001, 19.148],
                      [-19.148, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [280.509, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 393',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 393,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.574],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.574],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [193.472, 1646.034], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 394',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 394,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 395',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 395,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 396',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 396,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 397',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 397,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 398',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 398,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 399',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 399,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 400',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 400,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 401',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 401,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [666.083, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 402',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 402,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [579.045, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 403',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 403,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [492.009, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 404',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 404,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.574, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [404.972, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 405',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 405,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [317.935, 1587.29], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 406',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 406,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 407',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 407,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.001, 19.148],
                      [-19.149, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 408',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 408,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 409',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 409,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 410',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 410,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 411',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 411,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.574, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [-0.001, 19.148],
                      [-19.148, 0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 412',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 412,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 413',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 413,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0.001, 19.148],
                      [-19.148, 0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [715.693, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 414',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 414,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0.001],
                      [0.001, 19.148],
                      [-19.148, 0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [628.657, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 415',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 415,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [541.62, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 416',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 416,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [454.583, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 417',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 417,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.574],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.574],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0.001],
                      [0, 19.148],
                      [-19.148, 0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [367.546, 1529.845], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 418',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 418,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 419',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 419,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 420',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 420,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 421',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 421,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 422',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 422,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 423',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 423,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 424',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 424,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 425',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 425,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [666.083, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 426',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 426,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [579.045, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 427',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 427,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [492.009, 1471.102], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 428',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 428,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 429',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 429,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.001, 19.148],
                      [-19.149, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 430',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 430,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 431',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 431,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 432',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 432,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 433',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 433,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 434',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 434,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 435',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 435,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [715.693, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 436',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 436,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [628.657, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 437',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 437,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [541.62, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 438',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 438,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [454.583, 1413.658], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 439',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 439,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 440',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 440,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 441',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 441,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 442',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 442,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 443',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 443,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 444',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 444,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 445',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 445,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 446',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 446,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [666.083, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 447',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 447,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [579.045, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 448',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 448,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [492.009, 1354.913], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 449',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 449,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.577],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.147, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 450',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 450,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.577],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [-0.001, 19.148],
                      [-19.149, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 451',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 451,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.577],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 452',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 452,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.577],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 453',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 453,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.577],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 454',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 454,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.577],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [-0.001, 19.148],
                      [-19.148, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 455',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 455,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.577],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 456',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 456,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.577],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [715.693, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 457',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 457,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.577],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [628.657, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 458',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 458,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.577],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.577],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [541.62, 1297.469], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 459',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 459,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 460',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 460,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 461',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 461,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 462',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 462,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 463',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 463,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 464',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 464,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 465',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 465,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 466',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 466,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [666.083, 1238.725], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 467',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 467,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 468',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 468,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.001, 19.148],
                      [-19.149, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 469',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 469,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 470',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 470,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 471',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 471,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 472',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 472,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 473',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 473,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 474',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 474,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [715.693, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 475',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 475,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [628.657, 1181.28], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 476',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 476,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 477',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 477,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 478',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 478,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 479',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 479,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 480',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 480,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 481',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 481,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 482',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 482,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 483',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 483,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [666.083, 1122.536], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 484',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 484,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 485',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 485,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.001, 19.148],
                      [-19.149, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 486',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 486,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 487',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 487,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 488',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 488,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 489',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 489,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 490',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 490,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 491',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 491,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [715.693, 1065.092], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 492',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 492,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 493',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 493,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 494',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 494,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, -0.001],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 495',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 495,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 496',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 496,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 497',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 497,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 1006.348], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 498',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 498,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 499',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 499,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.001, 19.148],
                      [-19.149, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 500',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 500,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 501',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 501,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 502',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 502,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 948.903], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 503',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 503,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 504',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 504,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.148, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 505',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 505,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0.001, 19.148],
                      [-19.148, -0.001],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 506',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 506,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [0, 19.148],
                      [-19.149, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 890.16], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 507',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 507,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 508',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 508,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 509',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 509,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 510',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 510,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 511',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 511,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 832.715], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 512',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 512,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1101.268, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 513',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 513,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 514',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 514,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 515',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 515,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 516',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 516,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [753.12, 773.971], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 517',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 517,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 518',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 518,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 519',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 519,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 520',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 520,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 521',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 521,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 716.527], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 522',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 522,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 523',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 523,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [927.193, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 524',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 524,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.576],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 657.783], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 525',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 525,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 526',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 526,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.001, 19.148],
                      [-19.149, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 527',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 527,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [976.805, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 528',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 528,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 529',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 529,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 600.339], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 530',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 530,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 531',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 531,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 532',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 532,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 541.595], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 533',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 533,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 534',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 534,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [-0.001, 19.148],
                      [-19.149, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 535',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 535,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.575, 0],
                      [0, 10.576],
                      [-10.576, 0.001],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.576, 0],
                      [0, -10.575],
                      [10.575, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.148, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [802.731, 484.15], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 536',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 536,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 537',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 537,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 538',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 538,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [840.156, 425.406], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 539',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 539,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.147, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 540',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 540,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [-0.001, 19.148],
                      [-19.149, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 541',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 541,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [-0.001, 19.148],
                      [-19.148, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 367.962], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 542',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 542,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 309.218], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 543',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 543,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [0, 19.148],
                      [-19.147, -0.001],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 251.774], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 544',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 544,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.149, -0.001],
                      [-0.001, 19.148],
                      [-19.149, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1237.916, 251.774], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 545',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 545,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.576],
                      [-10.574, 0],
                    ],
                    o: [
                      [0, 10.576],
                      [-10.574, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, -0.001],
                      [-0.001, 19.148],
                      [-19.148, -0.001],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [889.768, 251.774], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 546',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 546,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 547',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 547,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.575],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [-0.001, 19.148],
                      [-19.148, 0],
                      [-0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1014.23, 193.029], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 548',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 548,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1150.878, 135.585], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 549',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 549,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.576, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.576, 0],
                      [0, -10.576],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.001, 19.148],
                      [-19.148, 0],
                      [0.001, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1063.841, 135.585], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 550',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 550,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.149, 0],
                      [0, 19.148],
                      [-19.149, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1275.342, 76.841], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 551',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 551,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.575],
                      [10.574, 0],
                      [0, 10.575],
                      [-10.577, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.577, 0],
                      [0, -10.575],
                      [10.574, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0.002, 19.148],
                      [-19.148, 0],
                      [0.002, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1188.303, 76.841], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 552',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 552,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -10.576],
                      [10.576, 0],
                      [0, 10.575],
                      [-10.575, 0],
                    ],
                    o: [
                      [0, 10.575],
                      [-10.575, 0],
                      [0, -10.576],
                      [10.576, 0],
                    ],
                    v: [
                      [19.148, 0],
                      [0, 19.148],
                      [-19.147, 0],
                      [0, -19.148],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.913725490196, 0.941176470588, 0.964705882353, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1324.952, 19.398], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 553',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 553,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 870.000035435826,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  }
}
